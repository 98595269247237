import { useRef, useEffect } from 'react';
import { isEqual, deepMix } from '@antv/util';
import { utils } from '../util';
import createNode from '../util/createNode';
export default function useInit(ChartClass, config) {
    const chart = useRef();
    const chartOptions = useRef();
    const container = useRef(null);
    /**
     * Get data base64
     * @param {string} type A DOMString indicating the image format. The default format type is image/png.
     * @param {number} encoderOptions A Number between 0 and 1 indicating the image quality
     */
    const toDataURL = (type = 'image/png', encoderOptions) => {
        return chart.current?.chart.canvas.cfg.el.toDataURL(type, encoderOptions);
    };
    /**
     * Download Iamge
     * @param {string} name A name of image
     * @param {string} type A DOMString indicating the image format. The default format type is image/png.
     * @param {number} encoderOptions A Number between 0 and 1 indicating the image quality
     */
    const downloadImage = (name, type = 'image/png', encoderOptions) => {
        try {
            // default png
            if (name && name.indexOf('.') === -1) {
                name = `${name}.png`;
            }
            let imageName = name;
            if (!imageName) {
                const _config = config;
                // 默认值：图表 title -> 图表类型
                imageName = `${_config?.title?.text || ChartClass?.name}.png`;
            }
            const base64 = chart.current?.chart.canvas.cfg.el.toDataURL(type, encoderOptions);
            let a = document.createElement('a');
            a.href = base64;
            a.download = imageName;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            a = null;
        }
        catch (err) {
            console.log(err);
        }
    };
    const processConfig = () => {
        // @ts-ignore 该属性只有 Liquid 和 Dount 存在且配置不一致，类型定义先忽略
        if (config.statistic?.htmlContent) {
            // @ts-ignore
            const statisticHtmlContent = config.statistic.htmlContent;
            // @ts-ignore
            config.statistic.htmlContent = (...arg) => {
                const statisticDom = statisticHtmlContent(...arg);
                if (utils.isType(statisticDom, 'String') ||
                    utils.isType(statisticDom, 'Number') ||
                    utils.isType(statisticDom, 'HTMLDivElement')) {
                    return statisticDom;
                }
                return createNode(statisticDom);
            };
        }
        if (typeof config.tooltip === 'object') {
            if (config.tooltip?.container) {
                config.tooltip.container = createNode(config.tooltip.container);
            }
            if (config.tooltip?.customContent) {
                const customContent = config.tooltip.customContent;
                config.tooltip.customContent = (title, items) => {
                    const tooltipDom = customContent(title, items) || '';
                    if (utils.isType(tooltipDom, 'HTMLDivElement')) {
                        return tooltipDom;
                    }
                    return createNode(tooltipDom);
                };
            }
        }
    };
    useEffect(() => {
        if (chart.current && !isEqual(chartOptions.current, config)) {
            let changeData = false;
            if (chartOptions.current) {
                // 从 options 里面取出 data 、value 、 percent 进行比对，判断是否仅数值发生改变
                const { data: currentData, value: currentValue, percent: currentPercent, ...currentConfig } = chartOptions.current;
                const { data: inputData, value: inputValue, percent: inputPercent, ...inputConfig } = config;
                changeData = isEqual(currentConfig, inputConfig);
            }
            if (changeData) {
                let changeType = 'data';
                const typeMaps = ['percent', 'value'];
                const currentKeys = Object.keys(config);
                typeMaps.forEach((type) => {
                    if (currentKeys.includes(type)) {
                        changeType = type;
                    }
                });
                chart.current.changeData(config?.[changeType] || []);
            }
            else {
                processConfig();
                chart.current.update(deepMix(chart.current.options, config));
            }
            chartOptions.current = config;
        }
    }, [config]);
    useEffect(() => {
        if (!container.current) {
            return () => null;
        }
        processConfig();
        const chartInstance = new ChartClass(container.current, {
            ...config,
        });
        chartInstance.__proto__.toDataURL = (type, encoderOptions) => {
            return toDataURL(type, encoderOptions);
        };
        chartInstance.__proto__.downloadImage = (name, type, encoderOptions) => {
            return downloadImage(name, type, encoderOptions);
        };
        chartInstance.render();
        if (!chartOptions.current) {
            chartOptions.current = config;
        }
        chart.current = utils.clone(chartInstance);
        return () => {
            chartInstance.destroy();
        };
    }, []);
    return {
        chart,
        container,
    };
}
