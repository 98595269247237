import React, { useEffect, useImperativeHandle, forwardRef } from 'react';
import { Heatmap as G2plotHeatmap } from '@antv/g2plot';
import useChart from '../hooks/useChart';
import { getChart } from '../util';
import { ErrorBoundary } from '../base';
import ChartLoading from '../util/createLoading';
const HeatmapChart = forwardRef((props, ref) => {
    const { chartRef, style = {
        height: '100%',
    }, className, loading, loadingTemplate, errorTemplate, ...rest } = props;
    const { chart, container } = useChart(G2plotHeatmap, rest);
    useEffect(() => {
        getChart(chartRef, chart.current);
    }, [chart.current]);
    useImperativeHandle(ref, () => ({
        getChart: () => chart.current,
    }));
    return (React.createElement(ErrorBoundary, { errorTemplate: errorTemplate },
        loading && React.createElement(ChartLoading, { loadingTemplate: loadingTemplate }),
        React.createElement("div", { className: className, style: style, ref: container })));
});
export default HeatmapChart;
