import React from 'react';
class ErrorBoundary extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            hasError: false,
        };
        this.renderError = (e) => {
            const { errorTemplate } = this.props;
            switch (e) {
                default:
                    // fallback
                    return errorTemplate && typeof errorTemplate === 'function' ? (errorTemplate(e)) : (React.createElement("h5", null,
                        "\u7EC4\u4EF6\u51FA\u9519\u4E86\uFF0C\u8BF7\u6838\u67E5\u540E\u91CD\u8BD5\uFF1A ",
                        e.message));
            }
        };
    }
    static getDerivedStateFromError(error) {
        return { hasError: true, error };
    }
    static getDerivedStateFromProps(nextProps, state) {
        if (state.children !== nextProps.children) {
            return {
                children: nextProps.children,
                hasError: false,
                error: undefined,
            };
        }
        return null;
    }
    render() {
        if (this.state.hasError) {
            return this.renderError(this.state.error);
        }
        return React.createElement("div", { style: { position: 'relative', height: '100%' } }, this.props.children);
    }
}
export default ErrorBoundary;
