import React from 'react';
import ContentLoader from 'react-content-loader';
const ChartLoading = ({ type, loadingTemplate }) => {
    const renderLoading = () => {
        if (loadingTemplate) {
            return loadingTemplate;
        }
        let loadingElement;
        switch (type) {
            case 'bar':
            case 'column':
            case 'pie':
            default:
                loadingElement = (React.createElement(ContentLoader, { viewBox: "0 0 400 180", width: 200, height: 90, speed: 1 },
                    React.createElement("rect", { x: "20", y: "5", rx: "0", ry: "0", width: "1", height: "170" }),
                    React.createElement("rect", { x: "20", y: "175", rx: "0", ry: "0", width: "360", height: "1" }),
                    React.createElement("rect", { x: "40", y: "75", rx: "0", ry: "0", width: "35", height: "100" }),
                    React.createElement("rect", { x: "80", y: "125", rx: "0", ry: "0", width: "35", height: "50" }),
                    React.createElement("rect", { x: "120", y: "105", rx: "0", ry: "0", width: "35", height: "70" }),
                    React.createElement("rect", { x: "160", y: "35", rx: "0", ry: "0", width: "35", height: "140" }),
                    React.createElement("rect", { x: "200", y: "55", rx: "0", ry: "0", width: "35", height: "120" }),
                    React.createElement("rect", { x: "240", y: "15", rx: "0", ry: "0", width: "35", height: "160" }),
                    React.createElement("rect", { x: "280", y: "135", rx: "0", ry: "0", width: "35", height: "40" }),
                    React.createElement("rect", { x: "320", y: "85", rx: "0", ry: "0", width: "35", height: "90" })));
        }
        return loadingElement;
    };
    return (React.createElement("div", { style: {
            position: 'absolute',
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            left: 0,
            top: 0,
            zIndex: 99,
            backgroundColor: '#fff',
        } }, renderLoading()));
};
export default ChartLoading;
