// 此文件在构建时会自动更新，请勿手动修改，详见 package.json 中的 entry script
import Area from './area';
import Bar from './bar';
import Bullet from './bullet';
import Column from './column';
import Funnel from './funnel';
import Histogram from './histogram';
import Line from './line';
import Box from './box';
import Liquid from './liquid';
import Heatmap from './heatmap';
import Pie from './pie';
import Gauge from './gauge';
import Progress from './progress';
import Radar from './radar';
import RingProgress from './ringProgress';
import Rose from './rose';
import Scatter from './scatter';
import TinyArea from './tinyArea';
import TinyColumn from './tinyColumn';
import DualAxes from './dualAxes';
import TinyLine from './tinyLine';
import Waterfall from './waterfall';
import WordCloud from './wordCloud';
import Sunburst from './sunburst';
import Stock from './stock';
import RadialBar from './radialBar';
import { OrganizationTreeGraph, DagreGraph, IndentedTree, DagreFundFlowGraph } from './graph';
import { G2 } from '@antv/g2plot';
export { Area, Bar, Box, Bullet, Column, Funnel, Histogram, Line, Liquid, Heatmap, Pie, Progress, Radar, RingProgress, Rose, Scatter, TinyArea, TinyColumn, TinyLine, Waterfall, WordCloud, Sunburst, DualAxes, Stock, RadialBar, Gauge, OrganizationTreeGraph, DagreGraph, IndentedTree, DagreFundFlowGraph, G2, };
export default {
    Area,
    Bar,
    Box,
    Bullet,
    Column,
    Funnel,
    Histogram,
    Line,
    Liquid,
    Heatmap,
    Pie,
    Progress,
    Radar,
    RingProgress,
    Rose,
    Scatter,
    TinyArea,
    TinyColumn,
    TinyLine,
    Waterfall,
    WordCloud,
    Sunburst,
    DualAxes,
    Stock,
    RadialBar,
    Gauge,
    OrganizationTreeGraph,
    DagreGraph,
    IndentedTree,
    DagreFundFlowGraph,
    G2,
};
